<template>
    <div class="chat-page">
      <h1>채팅</h1>
      <StoreChat :storeId="storeId" />
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useRoute } from 'vue-router';
  import StoreChat from '@/components/StoreChat.vue';
  
  export default {
    name: 'ChatPage',
    components: {
      StoreChat
    },
    setup() {
      const route = useRoute();
      const storeId = ref(parseInt(route.params.storeId));
  
      return {
        storeId
      };
    }
  }
  </script>