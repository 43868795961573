<template>
  <div class="voice-chat-container">
    <div class="voice-chat-controls">
      <button @click="toggleRecording" :disabled="isRecording">
        <span style="display: none;">{{ isVoiceChatting ? '녹음 종료' : '음성 채팅 시작' }}</span>
      </button>
      <div v-if="isVoiceChatting && isListening" class="recording-indicator"></div>
    </div>

    <div class="chat-messages" ref="chatMessages">
      <div v-for="(message, index) in messages" :key="index" :class="message.role">
        <div v-html="renderMarkdown(message.content)"></div>
      </div>
    </div>
    <p v-if="recognizedText" style="display: none;">인식된 텍스트: {{ recognizedText }}</p>
    <audio ref="audioPlayer" controls style="display: none;"></audio>
    <audio ref="testAudioPlayer" style="display: none;"></audio>
    <button @click="playTestAudio" style="display: none;">테스트 오디오 재생</button>
    <button @click="toggleDebugging"  style="display: none;">Toggle Debugging</button>
    <div v-if="isDebugging" class="level-meter-container" style="display: none;">
      <div class="recording-duration" style="color: gray;">녹음 {{ formattedRecordingDuration }}</div>
      <div class="level-meter">
        <div class="level-bar" :style="{ width: levelBarWidth + '%' }"></div>
        <div class="silence-threshold" :style="{ left: silenceThresholdPosition + '%' }"></div>
        <div class="noise-level" :style="{ left: noiseLevelPosition + '%' }"></div>
        <div class="level-value">{{ currentLevel.toFixed(2) }} dB</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, nextTick } from 'vue';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { sendVoiceChatMessage } from '@/api/store.js?v=20';
import { calculateVolume, isSilence, removeSilence, mergeAudioBuffers, createWavBlob, logAudioData } from '@/utils/audioUtils.js?v=1';
const LOG_OUTPUT = false;

const log = (...args) => {
  if (LOG_OUTPUT) {
    console.log(...args);
  }
};

export default {
  name: 'VoiceChat',
  props: {
    storeId: {
      type: Number,
      required: true
    }
  },
  emits: ['message', 'recordingStarted', 'recordingEnded', 'voiceChatStateChanged'],
  setup(props, { emit }) {
    const messages = ref([]);
    const chatMessages = ref(null);
    const audioPlayer = ref(null);
    const isVoiceChatting = ref(false);
    const recognizedText = ref('');
    const testAudioPlayer = ref(null);
    let testAudioUrl = null;

    let audioContext;
    let mediaStreamSource;
    let recorder;
    const audioChunks = ref([]);
    const isListening = ref(false);
    let recordingStartTime = 0;
    let lastSoundTime = 0;

    let silenceThreshold = -40; // dB, 환경에 따라 조정 필요
    const SAMPLE_RATE = 16000;
    const API_SAMPLE_RATE = 16000;
    const MIN_RECORDING_LENGTH = 1000; // 1초
    const MAX_SILENCE_DURATION = 2000; // 1초
    const MIN_VALID_AUDIO_LENGTH = SAMPLE_RATE * 2; // 2초 분량의 샘플

    const audioWorkletReady = ref(false);
    const isRecording = ref(false);
    const isPlayingTTS = ref(false);
    const shouldResumeRecording = ref(false);

    const voiceChatState = ref('idle'); // 'idle', 'recording', 'waiting', 'playing'

    const MAX_RECORDING_TIME = 20000; // 20초

    const VOLUME_HISTORY_SIZE = 10;
    const volumeHistory = [];
    let isSpeaking = false;
    let silenceStartTime = 0;

    const isDebugging = ref(true); // 디버깅 모드 활성화 여부
    const currentLevel = ref(-60); // 현재 음량 레벨
    const MIN_LEVEL = -60; // dB
    const MAX_LEVEL = 0; // dB

    const levelBarWidth = computed(() => {
      const percentage = (currentLevel.value - MIN_LEVEL) / (MAX_LEVEL - MIN_LEVEL) * 100;
      return Math.min(Math.max(percentage, 0), 100);
    });

    const silenceThresholdPosition = ref(0); // ref로 변경
    const noiseLevelPosition = computed(() => {
      const position = ((noiseLevel.value - MIN_LEVEL) / (MAX_LEVEL - MIN_LEVEL)) * 100;
      console.log(`Noise level position: ${position}%`);
      return Math.max(position, 1); // 최소 1%로 설정하여 시각적으로 표시되도록 함
    });

    const recordingDuration = ref(0);

    const formattedRecordingDuration = computed(() => {
      const seconds = Math.floor(recordingDuration.value / 1000);
      const tenths = Math.floor((recordingDuration.value % 1000) / 100);
      return `${seconds}.${tenths}초`;
    });

    const isStopping = ref(false); // 녹음 중지 상태 변수 추가

    const recordStartedTime = ref(0);

    const voiceChatStartTime = ref(0);

    const noiseLevel = ref(-50); // 초기 소음 수준

    const measureNoiseLevel = async () => {
      console.log('Measuring noise level...');
      try {
          if (!audioContext) {
              audioContext = new (window.AudioContext || window.webkitAudioContext)();
          }

          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const mediaStreamSource = audioContext.createMediaStreamSource(stream);
          const analyser = audioContext.createAnalyser();
          mediaStreamSource.connect(analyser);
          analyser.fftSize = 2048;
          const dataArray = new Float32Array(analyser.frequencyBinCount);

          let measurements = [];
          for (let i = 0; i < 10; i++) {
              analyser.getFloatTimeDomainData(dataArray);
              const rms = Math.sqrt(dataArray.reduce((acc, val) => acc + val * val, 0) / dataArray.length);
              const db = 20 * Math.log10(rms);
              if (isFinite(db)) {
                  measurements.push(db);
              }
              await new Promise(resolve => setTimeout(resolve, 100));
          }

          if (measurements.length > 0) {
              noiseLevel.value = measurements.reduce((a, b) => a + b, 0) / measurements.length;
              console.log(`Measured noise level: ${noiseLevel.value.toFixed(2)} dB`);
          } else {
              console.warn('No valid measurements obtained');
              noiseLevel.value = -60; // 기본값 설정
          }

          // 스트림 정리
          stream.getTracks().forEach(track => track.stop());
      } catch (error) {
          console.error('Error measuring noise level:', error);
          if (error.name === 'NotAllowedError') {
              console.error('Microphone permission denied');
          }
          noiseLevel.value = -60; // 에러 시 기본값 설정
      }
      console.log(`Updated noise level: ${noiseLevel.value}`);
    };

    const adjustSilenceThreshold = (maxLevel) => {
        const newThreshold = Math.max(Math.min(noiseLevel.value + 10, -10), -60); // 소음 수준에 10dB 여유를 두고 설정
        if (newThreshold !== silenceThreshold) {
            console.log(`Adjusting silenceThreshold:`);
            console.log(`From: ${visualizeLevel(silenceThreshold)}`);
            console.log(`To:   ${visualizeLevel(newThreshold)}`);
            silenceThreshold = newThreshold;
            // silenceThresholdPosition 업데이트
            silenceThresholdPosition.value = ((silenceThreshold - MIN_LEVEL) / (MAX_LEVEL - MIN_LEVEL)) * 100;
        }
    };

    const loadAudioWorklet = async () => {
      try {
        console.log('Starting to load AudioWorklet...');
        // audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: SAMPLE_RATE,
        });
        console.log('System sample rate:', audioContext.sampleRate);
        
        const workletUrl = new URL('/recorder-worklet.js', window.location.origin);
        console.log('AudioWorklet URL:', workletUrl.toString());
        
        await audioContext.audioWorklet.addModule('/recorder-worklet.js');
        console.log('AudioWorklet module loaded');        
        
        audioWorkletReady.value = true;
        console.log('AudioWorklet loaded successfully');
      } catch (error) {
        console.error('Failed to load AudioWorklet:', error);
        console.error('Error details:', error.message);
        if (error.stack) {
          console.error('Error stack:', error.stack);
        }
      }
    };

    const renderMarkdown = (content) => {
      if (!content) {
        console.warn('renderMarkdown received empty content');
        return '';
      }
      const rawHtml = marked(content);
      return DOMPurify.sanitize(rawHtml);
    };

    const toggleRecording = async () => {
      console.log("toggleRecording()");

      if (isVoiceChatting.value) {
        await stopRecording();
      } else {
        await startRecording();
      }
    };

    const startRecording = async () => {
      console.log("startRecording()");
      if (isPlayingTTS.value) {
        console.log('TTS 재생 중, startRecording() 종료');
        return null;
      }

      if (!audioWorkletReady.value) {
        console.error('AudioWorklet is not ready');
        return null;
      }

      if (isRecording.value && isStopping.value) {
        console.error('already recording and stopping');
        if (recorder && isStopping.value) {
          console.log('Waiting for recorder to stop...');
          await new Promise(resolve => {
            recorder.port.onmessage = (e) => {
              if (e.data.eventType === 'stopped') {
                isStopping.value = false;
                resolve();
              }
            };
          });
        } else {
          return null;
        }
      }

      // 음성 채팅 시작 시 소음 수준 측정
      await measureNoiseLevel();

      isRecording.value = true;
      audioChunks.value = [];
      isListening.value = true;
      recordingStartTime = Date.now();
      lastSoundTime = recordingStartTime;
      recordStartedTime.value = Date.now();
      if (voiceChatStartTime.value === 0) {
        voiceChatStartTime.value = Date.now();
      }

      try {
        if (audioContext.state === 'suspended') {
          await audioContext.resume();
        }

        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            echoCancellation: true,
            noiseSuppression: true
          } 
        });
        
        mediaStreamSource = audioContext.createMediaStreamSource(stream);
        
        recorder = new AudioWorkletNode(audioContext, 'recorder-worklet');
        mediaStreamSource.connect(recorder);
        recorder.connect(audioContext.destination);

        recorder.port.postMessage({ command: 'start' });
        isVoiceChatting.value = true;
        recognizedText.value = '음성 인식 중...';
        emit('recordingStarted');

        voiceChatState.value = 'recording';
        emit('voiceChatStateChanged', 'recording');

        return recorder;

      } catch (error) {
        console.error('Error accessing microphone:', error);
        let errorMessage = '마이크 접근에 실패했습니다.';
        
        if (error.name === 'NotAllowedError') {
          errorMessage = '마이크 사용 권한이 거부되었습니다. 브라우저 설정에서 마이크 권한을 허용해주세요.';
        } else if (error.name === 'NotReadableError') {
          errorMessage = '마이크를 사용할 수 없습니다. 다른 애플리케이션이 마이크를 사용 중인지 확인해주세요.';
        }

        recognizedText.value = errorMessage;
        messages.value.push({ role: 'error', content: errorMessage });
        isListening.value = false;
        isRecording.value = false;
        
        // 오류 발생 시 부모 컴포넌트에 알림
        emit('voiceChatError', errorMessage);
        return null;
      }
    };

    function removeRepetitions(gptResponse) {
      console.log("removeRepetitions(gptResponse:" + gptResponse + ")");
        // 문장을 구분하는 정규식으로 문자열을 분리
        const sentences = gptResponse.split(/(?<=[.!?])\s+/);
        
        // 마지막 문장을 기준으로 중복 제거
        const lastSentence = sentences[sentences.length - 1];
        const firstOccurrence = gptResponse.indexOf(lastSentence);
        
        // 마지막 문장이 처음 나타난 위치부터 그 이후의 문자열 반환
        let output = gptResponse.substring(firstOccurrence);
        console.log("removeRepetitions() output:" + output);
        return output;
    }


    // 허용 가능한 시차 (밀리초 단위)
    const TIME_DIFFERENCE_TOLERANCE = 300; // 예: 100ms의 오차 허용


    // 열거형 정의 (필요하다면 전역 범위로 이동)
    const RecordingResult = {
      PROCESSED: 'processed',
      RESTART: 'restart',
      ERROR: 'error'
    };

    const handleRecordingStop = async () => {
      console.log("handleRecordingStop()");
      if (isPlayingTTS.value) {
        console.log('TTS 재생 중, 녹음 무시');
        return RecordingResult.PROCESSED;
      }

      voiceChatState.value = 'waiting';
      emit('voiceChatStateChanged', 'waiting');

      if (audioChunks.value.length === 0) {
        console.warn('녹음된 오디오가 없어서 handleRecordingStop()을 종료합니다.');
        return RecordingResult.RESTART;
      }

      console.log(`처리 전 audioChunks 길이: ${audioChunks.value.length}`);

      try {
        let recordedAudioData = mergeAudioBuffers(audioChunks.value, audioContext.sampleRate);
        logAudioData('Merged Audio Data', recordedAudioData);
        
        if (!recordedAudioData || recordedAudioData.length < MIN_VALID_AUDIO_LENGTH || isSilence(recordedAudioData)) {
          console.log('녹음된 오디오가 너무 짧거나 묵음입니다.');
          return RecordingResult.RESTART;
        }

        if (audioContext.sampleRate !== API_SAMPLE_RATE) {
          const resampledBuffer = await resampleAudio(recordedAudioData, API_SAMPLE_RATE);
          recordedAudioData = resampledBuffer.getChannelData(0);
          logAudioData('After Resampling', recordedAudioData, API_SAMPLE_RATE);
        }

        const wavBlob = createWavBlob(recordedAudioData, API_SAMPLE_RATE, 1);
        const reqAudioUrl = URL.createObjectURL(wavBlob);
        console.log('요청 오디오 URL:', reqAudioUrl);
        const formData = new FormData();
        formData.append("audio_file", wavBlob, "audio.wav");
        formData.append("conversation_history", JSON.stringify(messages.value));

        let isNewGptResponse = true;
        let currentGptResponse = '';

        await sendVoiceChatMessage(props.storeId, formData, messages.value, (type, data) => {
          if (type === 'recognizedText') {
            nextTick(() => {
              if (!messages.value.some(msg => msg.role === 'user' && msg.content === data)) {
                messages.value.push({ role: 'user', content: data });
                scrollToBottom();
              }
            });
          } else if (type === 'gptResponse') {
            currentGptResponse = data;
            nextTick(() => {
              const lastMessage = messages.value[messages.value.length - 1];
              if (lastMessage.role === 'assistant') {
                lastMessage.content = removeRepetitions(currentGptResponse);
              } else {
                currentGptResponse = removeRepetitions(currentGptResponse);
                messages.value.push({ role: 'assistant', content: currentGptResponse });
              }
              scrollToBottom();
            });
          } else if (type === 'audioData') {
            const audioBlob = new Blob([base64ToArrayBuffer(data)], { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            audioPlayer.value.src = audioUrl;
            isPlayingTTS.value = true;  // TTS 재생 시작
            audioPlayer.value.onended = () => {
              isPlayingTTS.value = false;
              URL.revokeObjectURL(audioUrl);
            };

            audioPlayer.value.onerror = () => {
              console.error('Audio playback error');
              isPlayingTTS.value = false;  // 오류 발생 시 TTS 재생 상태 초기화
            };
            audioPlayer.value.play();
          }
        });

        console.log(`처리 완료. audioChunks 초기화 전 길이: ${audioChunks.value.length}`);
        audioChunks.value = [];
        console.log(`audioChunks 초기화 후 길이: ${audioChunks.value.length}`);

        return RecordingResult.PROCESSED;
      } catch (error) {
        console.error('음성 채팅 처리 중 오류 발생:', error);
        isPlayingTTS.value = false;  // 오류 발생 시 TTS 재생 상태 초기화
        if (error.response && error.response.status === 400) {
          console.log('인식된 음성이 없습니다.');
          return RecordingResult.RESTART;
        }
        return RecordingResult.ERROR;
      }
    };

    // base64 문자열을 ArrayBuffer로 변환하는 함수
    const base64ToArrayBuffer = (base64) => {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    };

    const stopRecording = async () => {
      console.log('stopRecording()');
      if (!isRecording.value) return RecordingResult.PROCESSED;

      isRecording.value = false;
      isStopping.value = true;
      isListening.value = false;
      console.log('Stopping recording');

      try {
        if (recorder) {
          recorder.port.postMessage({ command: 'stop' });
          const finalStats = await new Promise(resolve => {
            recorder.port.onmessage = (e) => {
              if (e.data.eventType === 'finalStats') {
                isStopping.value = false;
                resolve(e.data);
              }
            };
          });
          console.log(`Final recording stats:`);
          console.log(`Samples recorded: ${finalStats.samplesRecorded}`);
          adjustSilenceThreshold(finalStats.maxLevel);
          
          // Disconnect and nullify the recorder
          recorder.disconnect();
          mediaStreamSource.disconnect();
          recorder = null;
          mediaStreamSource = null;

          const result = await handleRecordingStop();
          isVoiceChatting.value = false;
          emit('recordingEnded');

          return result; // 여기서 handleRecordingStop()의 결과를 반환합니다.
        }
      } catch (error) {
        console.error('Error stopping recording:', error);
        isStopping.value = false;
        return RecordingResult.ERROR;
      }

      return RecordingResult.PROCESSED; // 기본적으로 PROCESSED를 반환합니다.
    };



    const createWavBlob = (audioData, sampleRate, numChannels) => {
      logAudioData('Before WAV Creation', audioData, sampleRate);
      
      const buffer = new ArrayBuffer(44 + audioData.length * 2);
      const view = new DataView(buffer);

      writeString(view, 0, 'RIFF');
      view.setUint32(4, 36 + audioData.length * 2, true);
      writeString(view, 8, 'WAVE');
      writeString(view, 12, 'fmt ');
      view.setUint32(16, 16, true);
      view.setUint16(20, 1, true);
      view.setUint16(22, numChannels, true);
      view.setUint32(24, sampleRate, true);
      view.setUint32(28, sampleRate * numChannels * 2, true);
      view.setUint16(32, numChannels * 2, true);
      view.setUint16(34, 16, true);
      writeString(view, 36, 'data');
      view.setUint32(40, audioData.length * 2, true);

      const length = audioData.length;
      let index = 44;
      for (let i = 0; i < length; i++) {
        view.setInt16(index, Math.max(-1, Math.min(1, audioData[i])) * 0x7FFF, true);
        index += 2;
      }

      const wavHeader = new Uint8Array(buffer.slice(0, 44));
      const wavSamples = new Int16Array(buffer.slice(44, 84));
      console.log(JSON.stringify({
        stage: 'WAV File Created',
        header: Array.from(wavHeader),
        samples: Array.from(wavSamples)
      }));

      return new Blob([buffer], { type: 'audio/wav' });
    };

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    const playTestAudio = () => {
      if (testAudioUrl) {
        testAudioPlayer.value.play();
      } else {
        console.log("테스트할 오디오가 없습니다.");
      }
    };

    const scrollToBottom = () => {
      nextTick(() => {
        if (chatMessages.value) {
          chatMessages.value.scrollTop = chatMessages.value.scrollHeight;
        }
      });
    };

    const getDefaultAudioDevice = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputs = devices.filter(device => device.kind === 'audioinput');
        
        // 기본 마이크 장치 찾기
        const defaultDevice = audioInputs.find(device => device.label.includes('default')) || audioInputs[0];
        
        if (defaultDevice) {
          console.log('기본 마이크 장치:', defaultDevice.label);
        } else {
          console.log('기본 마이크 장치를 찾을 수 없습니다.');
        }
      } catch (error) {
        console.error('오디오 장치 목록을 가져오는 중 오류 발생:', error);
      }
    };


    const resampleAudio = async (audioBuffer, targetSampleRate) => {
      console.log("resampleAudio(targetSampleRate:" + targetSampleRate + ") START");
      if (!audioBuffer || audioBuffer.duration <= 0) {
        console.error('Invalid audio buffer, cannot resample.');
        return null;
      }
      const offlineCtx = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        Math.max(1, Math.ceil(audioBuffer.duration * targetSampleRate)),
        targetSampleRate
      );
      const source = offlineCtx.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(offlineCtx.destination);
      source.start(0);
      console.log("resampleAudio() END: offlineCtx.startRendering()");
      try {
        const renderedBuffer = await offlineCtx.startRendering();
        return renderedBuffer;
      } catch (error) {
        console.error('Error during audio resampling:', error);
        return null;
      }
    };

    const visualizeLevel = (level, minLevel = -60, maxLevel = 0, width = 50) => {
      const normalizedLevel = Math.min(Math.max(level, minLevel), maxLevel);
      const percentage = (normalizedLevel - minLevel) / (maxLevel - minLevel);
      const barLength = Math.floor(percentage * width);
      const bar = '█'.repeat(barLength) + '░'.repeat(width - barLength);
      return `[${bar}] ${level.toFixed(2)} dB`;
    };

    const toggleDebugging = () => {
      isDebugging.value = !isDebugging.value;
    };

    watch(messages, scrollToBottom, { deep: true });

    // 주기적으로 스크롤을 조정하는 함수
    const startAutoScroll = () => {
      const scrollInterval = setInterval(() => {
        scrollToBottom();
      }, 500); // 500ms마다 스크롤 조정

      // 60초 후에 자동 스크롤 중지
      // setTimeout(() => {
      //   clearInterval(scrollInterval);
      // }, 60000);
    };

    onMounted(async () => {
        console.log('VoiceChat component mounted');
        await loadAudioWorklet();
        await getDefaultAudioDevice(); // 기본 마이크 장치명 출력
        scrollToBottom();
        console.log('Browser:', navigator.userAgent);
        console.log('AudioContext sample rate:', audioContext.sampleRate);
        startAutoScroll();
    });

    const isVoiceChatActive = ref(false);

    // 열거형 정의
    const AudioProcessingResult = {
      CONTINUE: 'continue',
      STOP: 'stop',
      STOP_AND_RESTART: 'stop_and_restart'
    };
   
    const processAudioChunk = async (audioBuffer) => {
      const buffer = new Float32Array(audioBuffer);
      const volume = calculateVolume(buffer);
      currentLevel.value = volume;
      volumeHistory.push(volume);
      if (volumeHistory.length > VOLUME_HISTORY_SIZE) {
        volumeHistory.shift();
      }

      const averageVolume = volumeHistory.reduce((a, b) => a + b, 0) / volumeHistory.length;
      const currentTime = Date.now();

      if (averageVolume > silenceThreshold) {
        lastSoundTime = currentTime;
      }

      recordingDuration.value = currentTime - recordingStartTime;

      // MAX_RECORDING_TIME 체크
      if (recordingDuration.value > MAX_RECORDING_TIME) {
        console.log("processAudioChunk - MAX_RECORDING_TIME reached");
        return AudioProcessingResult.STOP;
      }

      if (averageVolume <= silenceThreshold) {
        if (currentTime - lastSoundTime > MAX_SILENCE_DURATION) {
          console.log("processAudioChunk - MAX_SILENCE_DURATION reached");
          // 녹음 시작 직후 묵음 감지 시 다시 시작
          if (Math.abs(recordStartedTime.value - lastSoundTime) <= TIME_DIFFERENCE_TOLERANCE) {
            console.log("녹음 시작 직후 묵음 감지, 새로운 녹음 시작");
            return AudioProcessingResult.STOP_AND_RESTART;
          }
          // VoiceChat 시작 후 초기 3초 동안 짧은 녹음 감지 시 다 시작
          const timeSinceVoiceChatStart = currentTime - voiceChatStartTime.value;
          if (timeSinceVoiceChatStart <= 3000 && recordingDuration.value <= 3000) {
            console.log("VoiceChat 시작 후 초기 3초 동안 짧은 녹음 감지, 새로운 녹음 시작");
            return AudioProcessingResult.STOP_AND_RESTART;
          }
          return AudioProcessingResult.STOP;
        }
      } else {
        lastSoundTime = currentTime;
      }

      return AudioProcessingResult.CONTINUE;
    };

    const startVoiceChatLoop = async () => {
      isVoiceChatActive.value = true;
      while (isVoiceChatActive.value) {
        if (isPlayingTTS.value) {
          shouldResumeRecording.value = true;
          await new Promise(resolve => {
            audioPlayer.value.onended = () => {
              isPlayingTTS.value = false;
              resolve();
            };
          });
        }

        shouldResumeRecording.value = false;
        const recorder = await startRecording();
        if (!recorder) {
          console.error('Failed to start recording');
          break;
        }

        let shouldStopRecording = false;
        while (!shouldStopRecording) {
          await new Promise((resolve) => {
            recorder.port.onmessage = async (e) => {
              if (e.data.eventType === 'data') {
                audioChunks.value.push(e.data.audioBuffer);
                const result = await processAudioChunk(e.data.audioBuffer);
                // AudioWorklet에서 받은 raw 데이터 출력
                logAudioData('AudioWorklet Raw Data', e.data.audioBuffer);
                if (result !== AudioProcessingResult.CONTINUE) {
                  shouldStopRecording = true;
                  resolve();
                }
              }
            };
          });
        }

        await stopRecording();
        const result = await handleRecordingStop();
        if (result === RecordingResult.PROCESSED) {
          // 정상적으로 처리된 경우, TTS 재생을 기다립니다.
          // TTS 재생은 handleRecordingStop 내에서 시작됩니다.
        } else if (result === RecordingResult.ERROR) {
          // 오류 발생 시 루프 종료
          console.error('Recording error occurred. Stopping voice chat loop.');
          break;
        }
        // RESTART 경우 바로 다음 녹음 시작
      }
      isVoiceChatActive.value = false;
      emit('voiceChatEnded');
    };

    const stopVoiceChatLoop = () => {
      isVoiceChatActive.value = false;
    };

    return {
      messages,
      renderMarkdown,
      chatMessages,
      audioPlayer,
      isVoiceChatting,
      startRecording,
      stopRecording,
      recognizedText,
      testAudioPlayer,
      playTestAudio,
      isRecording,
      toggleRecording,
      isListening,
      voiceChatState,
      isDebugging,
      currentLevel,
      levelBarWidth,
      silenceThresholdPosition,
      toggleDebugging,
      formattedRecordingDuration,
      startVoiceChatLoop,
      stopVoiceChatLoop,
      isVoiceChatActive,
      isPlayingTTS,
      shouldResumeRecording,
    };
  }
};
</script>

<style scoped>
.voice-chat-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  border: none;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.voice-chat-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* 마크다운 스타일 추가 */
.chat-messages :deep(pre) {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

.chat-messages :deep(code) {
  font-family: 'Courier New', Courier, monospace;
}

.chat-messages :deep(p) {
  margin-bottom: 10px;
}

.chat-messages :deep(ul), .chat-messages :deep(ol) {
  padding-left: 20px;
}

.user, .assistant, .error {
  margin-bottom: 20px; /* 글자 크기 2배로 키우기 위해 패딩 증가 */
  padding: 10px 20px; /* 글자 크기 2배로 키우기 위해 패딩 증가 */
  border-radius: 5px;
  max-width: 80%;
  font-size: 2em; /* 글자 크기 2배로 설정 */
}

.user {
  background-color: transparent;
  border: 1px solid white;
  align-self: flex-end;
  margin-left: auto;
  color: #0F9ED5;
}

.assistant {
  background-color: transparent;
  border: 1px solid white;
  align-self: flex-start;
  color: #595959;
}

.error {
  background-color: #ffe6e6;
  align-self: flex-start;
}

.recording-indicator {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: red;
  /* margin-left: 10px; */
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.level-meter-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 200px;
}

.recording-duration {
  text-align: right;
  font-size: 14px;
  margin-bottom: 5px;
}

.level-meter {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.level-bar {
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.1s ease-in-out;
}

.silence-threshold {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #FF0000;
}

.noise-level {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: blue;
}

.level-value {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 12px;
}
</style>