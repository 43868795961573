const API_SAMPLE_RATE = 16000;

export const calculateVolume = (buffer) => {
    let sum = 0;
    for (let i = 0; i < buffer.length; i++) {
        sum += buffer[i] * buffer[i];
    }
    const rms = Math.sqrt(sum / buffer.length);
    const volume = 20 * Math.log10(rms);
    return isFinite(volume) ? volume : -Infinity;
};

export const isSilence = (audioData, threshold = 0.01) => {
    return audioData.every(sample => Math.abs(sample) < threshold);
};



// 묵음 제거 함수 (현재는 사용하지 않음)
export const removeSilence = (audioData, threshold = 0.01) => {
    return; // 묵음 제거 필요 없음  

    logAudioData('Before Silence Removal', audioData);

    // 앞쪽 묵음 제거
    let startIndex = 0;
    while (startIndex < audioData.length && Math.abs(audioData[startIndex]) <= threshold) {
        startIndex++;
    }

    // 뒤쪽 묵음 제거
    let endIndex = audioData.length - 1;
    while (endIndex >= 0 && Math.abs(audioData[endIndex]) <= threshold) {
        endIndex--;
    }

    // 앞뒤 묵음이 제거된 데이터 반환
    const result = audioData.slice(startIndex, endIndex + 1);

    logAudioData('After Silence Removal', result);
    return result;
};


export const mergeAudioBuffers = (buffers, sampleRate) => {
    let totalLength = buffers.reduce((acc, buf) => acc + buf.length, 0);
    let result = new Float32Array(totalLength);
    let offset = 0;
    for (let buffer of buffers) {
      result.set(buffer, offset);
      offset += buffer.length;
    }
    console.log('Merged audio buffer length:', result.length);
    return result;
};


export const logAudioData = (stage, data, sampleRate = API_SAMPLE_RATE) => {
    const debug = false;
    if(!debug) return;

    const sampleData = data.slice(0, 20).map(v => Number(v.toFixed(6)));
    console.log(JSON.stringify({
      stage: stage,
      sampleRate: sampleRate,
      dataLength: data.length,
      sampleData: sampleData,
      stats: {
        min: Math.min(...data),
        max: Math.max(...data),
        average: data.reduce((a, b) => a + b, 0) / data.length
      }
    }));
  };
