<template>
    <v-card>
      <v-card-title>{{ store.brand_name }}</v-card-title>
      <v-card-text>
        <p>주소: {{ store.address }}</p>
        <p>전화번호: {{ store.phone }}</p>
        <p>웹사이트: {{ store.website }}</p>
        <p>설명: {{ store.description }}</p>
        <p>영업시간:</p>
        <p>{{ store.opening_hours }}</p>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
    name: 'StoreInfo',
    props: {
      store: {
        type: Object,
        required: true
      }
    }
  }
  </script>