import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL || '/api/v1';

export const generateStoreInfo = async (storeName) => {
  const response = await axios.post(`${API_URL}/stores/auto-generate/${encodeURIComponent(storeName)}`);
  return response.data;
};

export const scrapeStoreMenu = async (storeId) => {
  const response = await axios.post(`${API_URL}/products/scrape-menu/${storeId}`);
  return response.data;
};

export const sendChatMessage = async (storeId, chatRequest) => {
  const response = await axios.post(`${API_URL}/chat/${storeId}`, chatRequest, {
    responseType: 'text',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'text/event-stream; charset=utf-8'
    }
  });
  return response.data;
};

export const sendVoiceChatMessage = async (storeId, formData, messages, onUpdate) => {
  try {
    formData.append('conversation_history', JSON.stringify(messages));

    const response = await axios.post(`${API_URL}/chat/${storeId}/voice`, formData, {
      responseType: 'text',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onDownloadProgress: (progressEvent) => {
        const dataChunk = progressEvent.event.target.responseText;
        processDataChunk(dataChunk, onUpdate);
      }
    });

    console.log('Voice chat request completed');
    
    return {
      recognizedText: '',
      responseText: '',
      audioData: null
    };
  } catch (error) {
    console.error('Error in sendVoiceChatMessage:', error);
    if (error.response && error.response.status === 400) {
      console.log('인식된 음성이 없습니다.');
      return { error: '인식된 음성이 없습니다.' };
    }
    return { error: error.message || '음성 채팅 중 오류가 발생했습니다.' };
  }
};

let lastGptResponse = '';
let buffer = '';
let lastRecognizedText = '';
let currentGptResponse = '';

function processDataChunk(dataChunk, onUpdate) {
  buffer += dataChunk;
  let startIndex = 0;
  let endIndex;

  while ((endIndex = buffer.indexOf('}', startIndex)) !== -1) {
    const jsonStr = buffer.slice(startIndex, endIndex + 1);
    try {
      const data = JSON.parse(jsonStr);
      
      if (data.recognized_text && data.recognized_text !== lastRecognizedText) {
        console.log('인식된 텍스트:', data.recognized_text);
        onUpdate('recognizedText', data.recognized_text);
        lastRecognizedText = data.recognized_text;
      } else if (data.gpt_response) {
        console.log('GPT 응답:', data.gpt_response);
        currentGptResponse += data.gpt_response;
        onUpdate('gptResponse', currentGptResponse);
      } else if (data.audio_data) {
        console.log('오디오 데이터 수신');
        onUpdate('audioData', data.audio_data);
      } else if (data.error) {
        console.error('서버 오류:', data.error);
        throw new Error(data.error);
      }
      
      startIndex = endIndex + 1;
    } catch (error) {
      console.error('JSON 파싱 오류:', error);
      console.error('파싱 실패한 원본 데이터:', jsonStr);
      console.error('현재 버퍼 상태:', buffer);
      console.error('시작 인덱스:', startIndex, '끝 인덱스:', endIndex);
      
      // 파싱 오류 발생 시 다음 유효한 JSON 객체를 찾기 위해 인덱스를 이동
      startIndex = buffer.indexOf('{', startIndex + 1);
      if (startIndex === -1) break;
    }
  }
  
  buffer = buffer.slice(startIndex);
}

// base64 문자열을 ArrayBuffer로 변환하는 함수
function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}