<template>
    <div class="home">
      <h1>RoboTalk</h1>
      <ul>
        <li v-for="store in stores" :key="store.id">
          {{ store.name }}
          <router-link :to="{ name: 'chat', params: { storeId: store.id } }">채팅하기</router-link>
        </li>
      </ul>      
      <v-btn to="/store" color="primary" class="mr-2">매장 정보 생성</v-btn>
      <v-btn to="/online" color="secondary">온라인 플랫폼</v-btn>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  
  export default {
    name: 'Home',
    setup() {
      const stores = ref([]);
  
      onMounted(async () => {
        try {
          const response = await axios.get('/api/v1/stores/');
          stores.value = response.data;
        } catch (error) {
          console.error('스토어 목록을 불러오는 데 실패했습니다:', error);
        }
      });
  
      return {
        stores
      };
    }
  }
  </script>