<template>
    <v-card>
      <v-card-title>실시간 정보 업데이트</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="updateStoreInfo">
          <v-text-field v-model="storeId" label="매장 ID"></v-text-field>
          <v-text-field v-model="updateInfo.name" label="매장 이름"></v-text-field>
          <v-text-field v-model="updateInfo.address" label="주소"></v-text-field>
          <v-text-field v-model="updateInfo.phone" label="전화번호"></v-text-field>
          <v-btn type="submit" color="primary">정보 업데이트</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import { ref } from 'vue'
  import axios from 'axios'
  
  export default {
    name: 'RealtimeUpdate',
    setup() {
      const storeId = ref('')
      const updateInfo = ref({
        name: '',
        address: '',
        phone: ''
      })
  
      const updateStoreInfo = async () => {
        try {
          const response = await axios.put(`${process.env.VUE_APP_API_URL}/stores/${storeId.value}`, updateInfo.value)
          alert('매장 정보가 업데이트되었습니다.')
          console.log(response.data)
        } catch (error) {
          console.error('Failed to update store info:', error)
          alert('매장 정보 업데이트에 실패했습니다.')
        }
      }
  
      return { storeId, updateInfo, updateStoreInfo }
    }
  }
  </script>