<template>
  <div>
    <h1>매장 정보</h1>
    <v-text-field v-model="storeName" label="매장 이름 입력" @keyup.enter="classifyKeyword"></v-text-field>
    <v-btn @click="classifyKeyword" color="primary">키워드 분류</v-btn>
    <div v-if="classificationResult">
      <p>키워드 유형: {{ classificationResult.keyword_type }}</p>
      <p>키워드: {{ classificationResult.keywords.join(', ') }}</p>
      <p>유사 키워드: {{ classificationResult.similar_keywords.join(', ') }}</p>
    </div>
    <v-btn @click="generateStoreInfo" color="secondary" :disabled="!classificationResult">매장 정보 생성</v-btn>
    <StoreInfo v-if="storeInfo" :store="storeInfo" />

    <!-- 스크래핑된 메뉴 표시 -->
    <v-btn @click="scrapeMenu" color="primary" :disabled="!storeInfo">메뉴 스크래핑</v-btn>
  
    <div v-if="scrapedMenu">
      <h2>스크래핑된 메뉴</h2>
      <ul>
        <li v-for="item in scrapedMenu" :key="item.id">
          {{ item.name }} - {{ item.pricing.selling_price }} {{ item.pricing.currency_code }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import StoreInfo from '@/components/StoreInfo.vue'
import axios from 'axios'
import { generateStoreInfo, scrapeStoreMenu } from '@/api/store' // 이 줄을 추가

export default {
  components: { StoreInfo },
  setup() {
    const storeName = ref('')
    const storeInfo = ref(null)
    const classificationResult = ref(null)
    const scrapedMenu = ref(null)

    onMounted(() => {
      console.log('API URL:', process.env.VUE_APP_API_URL)
    })

    const classifyKeyword = async () => {
      try {
        console.log('Classifying keyword:', storeName.value)
        const url = `${process.env.VUE_APP_API_URL}/stores/classify-keyword/${encodeURIComponent(storeName.value)}`
        console.log('API URL:', url)
        
        const response = await axios.post(url, {}, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        
        console.log('Classification response:', response.data)
        classificationResult.value = response.data
      } catch (error) {
        console.error('Failed to classify keyword:', error)
        if (error.response) {
          console.error('Error response:', error.response.data)
          console.error('Error status:', error.response.status)
          console.error('Error headers:', error.response.headers)
        } else if (error.request) {
          console.error('Error request:', error.request)
        } else {
          console.error('Error message:', error.message)
        }
        alert(`키워드 분류에 실패했습니다. 에러: ${error.message}`)
      }
    }

    const generateStoreInfo = async () => {
      try {
        console.log('Generating store info for:', storeName.value)
        console.log('API URL:', `${process.env.VUE_APP_API_URL}/stores/auto-generate/${encodeURIComponent(storeName.value)}`)
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/stores/auto-generate/${encodeURIComponent(storeName.value)}`)
        console.log('Store info response:', response.data)
        storeInfo.value = response.data
      } catch (error) {
        console.error('Failed to generate store info:', error)
        console.error('Error response:', error.response)
        alert(`매장 정보 생성에 실패했습니다. 에러: ${error.message}`)
      }
    }

    const scrapeMenu = async () => {
      try {
        if (!storeInfo.value || !storeInfo.value.id) {
          throw new Error('매장 정보가 없습니다.')
        }
        console.log('Scraping menu for store:', storeInfo.value.id)
        const response = await scrapeStoreMenu(storeInfo.value.id)
        console.log('Scraped menu:', response)
        scrapedMenu.value = response
      } catch (error) {
        console.error('Failed to scrape menu:', error)
        alert(`메뉴 스크래핑에 실패했습니다. 에러: ${error.message}`)
      }    
    }

    return { 
      storeName, 
      storeInfo, 
      classificationResult, 
      classifyKeyword, 
      generateStoreInfo,
      scrapeMenu, // 이 부분을 추가
      scrapedMenu // 이 부분도 추가
    }
  }
}
</script>