<!-- src/views/VoiceChatTest.vue -->
<template>
    <div class="voice-chat-test">
      <h1>VoiceChat 테스트</h1>
      <VoiceChat 
        :storeId="1"
        @message="handleMessage"
        @voiceChatStarted="handleVoiceChatStarted"
        @voiceChatEnded="handleVoiceChatEnded"
      />
      <div class="debug-info">
        <h2>디버그 정보</h2>
        <p>상태: {{ status }}</p>
        <p>마지막 메시지: {{ lastMessage }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import VoiceChat from '@/components/VoiceChat.vue';
  
  export default {
    name: 'VoiceChatTest',
    components: {
      VoiceChat
    },
    setup() {
      const status = ref('대기 중');
      const lastMessage = ref('');
  
      const handleMessage = (message) => {
        console.log('메시지 수신:', message);
        lastMessage.value = JSON.stringify(message);
      };
  
      const handleVoiceChatStarted = () => {
        console.log('음성 채팅 시작');
        status.value = '음성 채팅 중';
      };
  
      const handleVoiceChatEnded = () => {
        console.log('음성 채팅 종료');
        status.value = '음성 채팅 종료';
      };
  
      return {
        status,
        lastMessage,
        handleMessage,
        handleVoiceChatStarted,
        handleVoiceChatEnded
      };
    }
  };
  </script>
  
  <style scoped>
  .voice-chat-test {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .debug-info {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  </style>