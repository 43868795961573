<template>
    <div>
      <h1>온라인 플랫폼</h1>
      <RealtimeUpdate />
      <!-- 여기에 다른 온라인 플랫폼 관련 컴포넌트들을 추가할 수 있습니다 -->
    </div>
  </template>
  
  <script>
  import RealtimeUpdate from '@/components/RealtimeUpdate.vue'
  
  export default {
    name: 'OnlinePlatform',
    components: {
      RealtimeUpdate
    }
  }
  </script>